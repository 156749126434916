import AnalyticsService from '@root/core/src/services/analytics-service';
import AuthService from '@root/core/src/services/auth-service';
import CookieStorageService from '@root/core/src/services/cookie-storage-service';
import PropTypes from '@root/vendor/prop-types';
import React, { useCallback, useState } from '@root/vendor/react';
import StorageService from '@root/core/src/services/storage-service';
import UserSessionService from '@root/user-session/src/services/user-session-service';
import createTouchAttributionConfiguration from '@root/attribution/src/api/create-touch-attribution/create-touch-attribution-configuration';
import environment from '@root/core/src/utils/environment';
import useAssociateTouchAttribution from '@root/attribution/src/hooks/use-associate-touch-attribution';
import useLoginWithQueryParam from '@root/auth/src/hooks/use-login-with-query-param';
import useSafeImperativeNetworkRequest from '@root/core/src/hooks/use-safe-imperative-network-request';
import useValidateToken from '@root/auth/src/hooks/use-validate-token';
import { IsRootRightForYouExperiences } from '@root/core/src/models/experiences';
import { datadogRum } from '@root/vendor/@datadog/browser-rum';
import { useRootId } from '@root/attribution/src/hooks/use-root-id';

export default function AuthController({
  ProtectedApp,
  PublicRouter,
  SceneLoader,
  passProps: passPropsFromProps = {},
}) {
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  const [isQueryParamAuthenticated, isValidatingQueryParam] = useLoginWithQueryParam();
  const [associateTouchAttribution] = useAssociateTouchAttribution();
  const rootId = useRootId();
  const [createTouchAttribution] = useSafeImperativeNetworkRequest(createTouchAttributionConfiguration);
  const [shouldSkipIsRootRightForYou, setShouldSkipIsRootRightForYou] = useState(true);

  const isRootRightForYouExperience = IsRootRightForYouExperiences.CONTROL;

  const isValidatingToken = useValidateToken(useCallback((result) => {
    if (isQueryParamAuthenticated) {
      return;
    }

    const hasValidToken = !!result.data?.valid;

    if (!hasValidToken) {
      AuthService.clear();
      datadogRum.removeUser();
    }

    setIsAuthenticated(hasValidToken);
  }, [isQueryParamAuthenticated]));

  const updateShouldSkipIsRootRightForYou = (value) => {
    StorageService.setItem('SKIP_IS_ROOT_RIGHT_FOR_YOU_STORAGE_KEY', value);
    setShouldSkipIsRootRightForYou(value);
  };

  if (isValidatingToken || isValidatingQueryParam) {
    return <SceneLoader />;
  }

  const authenticationIsValid = isAuthenticated || isQueryParamAuthenticated;

  const passProps = {
    onLogin: () => {
      setIsAuthenticated(true);
      UserSessionService.initializeSessionActivity();
      associateTouchAttribution({
        rootId,
      });
    },
    onLogout: () => {
      AuthService.clear();
      datadogRum.removeUser();
      UserSessionService.clear();
      setIsAuthenticated(false);
      AnalyticsService.reset();
      createTouchAttribution({
        // eslint-disable-next-line root/prevent-use-of-window-location
        sourcePathUrl: CookieStorageService.get('core.attribution')?.sourcePathUrl || window.location.href,
        referrerUrl: document.referrer,
        rootId,
        mixpanelDistinctId: AnalyticsService.getDistinctId(),
      });
    },
    shouldSkipIsRootRightForYou,
    authenticationIsValid,
    isRootRightForYouExperience,
    updateShouldSkipIsRootRightForYou,
    ...passPropsFromProps,
  };

  if (authenticationIsValid && shouldSkipIsRootRightForYou) {
    return (
      <ProtectedApp
        inactiveTimeout={environment.inactiveTimeout}
        passProps={passProps}
      />
    );
  }

  return (
    <PublicRouter
      passProps={passProps}
    />
  );
}

AuthController.propTypes = {
  passProps: PropTypes.object,
  ProtectedApp: PropTypes.func.isRequired,
  PublicRouter: PropTypes.func.isRequired,
  SceneLoader: PropTypes.func.isRequired,
};
