export const Experiences = {
  ON: 'on',
  OFF: 'off',
  CA_OFF: 'california_off',
  CONTROL: 'control',
  EXCLUDED: 'excluded',
};

export const BypassQuoteLandingExperiences = {
  CONTROL: Experiences.CONTROL,
  EXCLUDED: Experiences.EXCLUDED,
  TABBED: 'tabbed',
  LINKED: 'linked',
};

export const DayZeroPacerScreenRehaul = {
  CONTROL: Experiences.CONTROL,
  SHOW_DAY_ZERO_PACER_REHAUL: 'show_day_zero_pacer_rehaul',
};

export const FullTermQuoteSavingsCalloutExperiences = {
  HIDE: 'hide_full_term_quote_savings_experience',
  SHOW: 'show_full_term_quote_savings_experience',
};

export const IsRootRightForYouExperiences = {
  CONTROL: Experiences.CONTROL,
  IS_ROOT_RIGHT_FOR_YOU: 'is_root_right_for_you',
};

export const IsRootRightForYouZipCodeExperiences = {
  INVALID_ZIP_CODE: 'invalid_zip_code',
  NON_TEST_DRIVE_MARKET: 'non_test_drive_market',
  MARKET_NOT_SUPPORTED: 'market_not_supported',
  FULL: 'full',
};

export const LicenseScanExperiences = {
  CONTROL: Experiences.CONTROL,
  LICENSE_SCAN_FOCUS: 'license_scan_focus',
  MANUAL_ENTRY_FOCUS: 'manual_entry_focus',
  BLENDED_MANUAL_AND_SCAN_FOCUS: 'blended_manual_and_scan_focus',
};

export const ProfileCreationPasswordRemovalExperiences = {
  CONTROL: Experiences.CONTROL,
  EXCLUDED: Experiences.EXCLUDED,
  PASSWORDLESS: 'passwordless',
};

export const PhoenixExperiences = {
  CONTROL: Experiences.CONTROL,
  NON_PHOENIX: 'non_phoenix',
  PHOENIX: 'phoenix',
};

export const PasswordAddPermittedExperiences = {
  ON: Experiences.ON,
  OFF: Experiences.OFF,
};

export const SkipPrefillPhoneNumberExperiences = {
  CONTROL: Experiences.CONTROL,
  SKIP_PREFILL_PHONE_NUMBER: 'skip_prefill_phone_number',
};

export const TopOfFunnelIntake = {
  CONTROL: Experiences.CONTROL,
  TIMING: 'timing',
  INFO: 'info',
};

export const AccountMatch = {
  POST_PREFILL_REPORT_MATCH: 'post_prefill_report_match',
  BOOMERANG_MATCH: 'boomerang_match',
  ACCOUNT_MATCH: 'account_match',
  NO_MATCH: 'no_match',
};

export const CollCompExperiences = {
  SKIP_COLL_COMP_PACER: 'skip_coll_comp_pacer',
  INCLUDE_COLL_COMP_PACER: 'include_coll_comp_pacer',
};

export const CollCompWarningModalExperiences = {
  COLL_COMP_WARNING_MODAL_DISABLED: 'coll_comp_warning_modal_disabled',
  COLL_COMP_WARNING_MODAL_ENABLED: 'coll_comp_warning_modal_enabled',
};

export const AdditionalBillingFieldExperiences = {
  CONTROL: 'control',
  SHOW_ADDITIONAL_FIELDS: 'show_additional_fields',
};
